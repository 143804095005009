export default function backRightClient(e) {
  var clientHeight = document.documentElement.clientHeight;
  var clientWidth = document.documentElement.clientWidth;
  var clientX = e.clientX;
  var clientY = e.clientY;

  if (clientX < 0) {
    clientX = 0;
  }

  if (clientX > clientWidth) {
    clientX = clientWidth;
  }

  if (clientY < 0) {
    clientY = 0;
  }

  if (clientY > clientHeight) {
    clientY = clientHeight;
  }

  return [clientX, clientY];
}