import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default function download(me) {
  return new Promise(function (resolve) {
    var imgUrl = me.snapshootList[me.snapshootList.length - 1];
    var a = document.createElement('a');

    if ('download' in a) {
      a.href = imgUrl;
      a.download = 'kss' + new Date().getTime() + '.png';
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', false, false);
      a.dispatchEvent(event);
    } else {
      var newImgUrl = imgUrl.replace('image/png', 'image/octet-stream');
      window.location.href = newImgUrl;
    }

    resolve();
  });
}