import { css, remove, typeChecking } from '../../util';
import drawMiddleImage from '../middleImage/drawMiddleImage';
import copy from '../copy';
import download from '../download';
import endAndClear from '../endAndClear';
export default function completeBT(me) {
  var completeBT = document.createElement('span');
  completeBT.id = 'kssCompleteBT';
  completeBT.className = 'kssToolbarItemBT';
  completeBT.innerHTML = '√完成';
  completeBT.title = '完成截图'; // completeBT.onkeydown = "cutPic()"

  css(completeBT, {
    width: '40px',
    'line-height': '28px'
  });
  var enterSwitch = true;
  completeBT.addEventListener("click", function () {
    me.isEdit = true;
    var lastShot = me.snapshootList[me.snapshootList.length - 1];
    copy(me, lastShot);
    me.needDownload === true && download(me);
    typeChecking(me.endCB) === '[object Function]' && me.endCB(lastShot);
    endAndClear(me);
    completeBT.removeEventListener("click", function () {});
  });

  if (enterSwitch) {
    document.onkeydown = function (event) {// var e = event || window.event;
      // if (e && e.keyCode == 13 && enterSwitch) { //回车键的键值为13
      //   completeBT.click(); //调用完成事件
      //   enterSwitch = false;
      // }
    };
  }

  return completeBT;
}