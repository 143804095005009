import "core-js/modules/es.array.fill.js";
import { computed } from '../util';
export default function arrow(startPos, endPos, triangle, me) {
  var startX = startPos.x * me.scale;
  var startY = startPos.y * me.scale;
  var endX = endPos.x * me.scale;
  var endY = endPos.y * me.scale;
  var MaxTwoSize = 20;
  computed(triangle, 'distance', ['twoSide', 'bottomSide', 'crossWidth'], [function (obj, baseValue, changeProperty) {
    if (baseValue / 2 * 1.1 <= MaxTwoSize) {
      obj[changeProperty] = baseValue / 2 * 1.1;
    } else {
      obj[changeProperty] = MaxTwoSize;
    }
  }, function (obj, baseValue, changeProperty) {
    if (baseValue / 2 * 1.1 <= MaxTwoSize) {
      obj[changeProperty] = baseValue / 2 * 1.1 * 0.8;
    } else {
      obj[changeProperty] = MaxTwoSize * 0.8;
    }
  }, function (obj, baseValue, changeProperty) {
    if (baseValue / 2 * 1.1 <= MaxTwoSize) {
      obj[changeProperty] = baseValue / 2 * 1.1 * 0.4;
    } else {
      obj[changeProperty] = MaxTwoSize * 0.4;
    }
  }]);
  var distance = Math.sqrt(Math.pow(startX - endX, 2) + Math.pow(startY - endY, 2));
  triangle.distance = distance;
  var h = Math.sqrt(Math.pow(triangle.twoSide, 2) - Math.pow(triangle.bottomSide / 2, 2));
  var x = Math.sqrt(Math.pow(h, 2) + Math.pow(triangle.crossWidth / 2, 2));
  var angle = Math.atan(triangle.bottomSide / 2 / h) * 180 / Math.PI;
  var angle1 = Math.atan(triangle.crossWidth / 2 / h) * 180 / Math.PI;
  var rightX, rightY, hX, hY, cX, cY, bX, bY; //当左上和右下时为一种情况，左下和右上为一种情况

  if (startX > endX && startY > endY || startX < endX && startY < endY) {
    var angle2 = Math.atan(Math.abs(startX - endX) / Math.abs(startY - endY)) * 180 / Math.PI;
    var y1 = Math.cos((angle1 + angle2) * 2 * Math.PI / 360) * x;
    var x1 = Math.sin((angle1 + angle2) * 2 * Math.PI / 360) * x;
    var symbol = 1;

    if (startX < endX && startY < endY) {
      symbol = -1;
    }

    rightX = endX + x1 * symbol;
    rightY = endY + y1 * symbol;
    var y2 = Math.cos((angle + angle2) * 2 * Math.PI / 360) * triangle.twoSide;
    var x2 = Math.sin((angle + angle2) * 2 * Math.PI / 360) * triangle.twoSide;
    hX = endX + x2 * symbol;
    hY = endY + y2 * symbol;
    var y3 = Math.cos(angle2 * 2 * Math.PI / 360) * h;
    var x3 = Math.sin(angle2 * 2 * Math.PI / 360) * h;
    var zX = endX + x3 * symbol;
    var zY = endY + y3 * symbol;
    bX = 2 * zX - rightX;
    bY = 2 * zY - rightY;
    cX = 2 * zX - hX;
    cY = 2 * zY - hY;
  } else if (startX < endX && startY > endY || startX > endX && startY < endY) {
    var _angle = Math.atan(Math.abs(startY - endY) / Math.abs(startX - endX)) * 180 / Math.PI;

    var _x = Math.cos((angle1 + _angle) * 2 * Math.PI / 360) * x;

    var _y = Math.sin((angle1 + _angle) * 2 * Math.PI / 360) * x;

    var _symbol = 1;

    if (startX < endX && startY > endY) {
      _symbol = -1;
    }

    rightX = endX + _x * _symbol;
    rightY = endY - _y * _symbol;

    var _x2 = Math.cos((angle + _angle) * 2 * Math.PI / 360) * triangle.twoSide;

    var _y2 = Math.sin((angle + _angle) * 2 * Math.PI / 360) * triangle.twoSide;

    hX = endX + _x2 * _symbol;
    hY = endY - _y2 * _symbol;

    var _x3 = Math.cos(_angle * 2 * Math.PI / 360) * h;

    var _y3 = Math.sin(_angle * 2 * Math.PI / 360) * h;

    var _zX = endX + _x3 * _symbol;

    var _zY = endY - _y3 * _symbol;

    bX = 2 * _zX - rightX;
    bY = 2 * _zY - rightY;
    cX = 2 * _zX - hX;
    cY = 2 * _zY - hY;
  } else if (startX === endX) {
    var _symbol2 = 1;

    if (startY < endY) {
      _symbol2 = -1;
    }

    var _zX2 = endX;

    var _zY2 = endY + h * _symbol2;

    rightX = _zX2 + triangle.crossWidth / 2 * _symbol2;
    rightY = _zY2;
    hX = _zX2 + triangle.bottomSide / 2 * _symbol2;
    hY = _zY2;
    bX = _zX2 - triangle.crossWidth / 2 * _symbol2;
    bY = _zY2;
    cX = _zX2 - triangle.bottomSide / 2 * _symbol2;
    cY = _zY2;
  } else if (startY === endY) {
    var _symbol3 = 1;

    if (startX < endX) {
      _symbol3 = -1;
    }

    var _zX3 = endX + h * _symbol3;

    var _zY3 = endY;
    rightX = _zX3;
    rightY = _zY3 + triangle.crossWidth / 2 * _symbol3;
    hX = _zX3;
    hY = _zY3 + triangle.bottomSide / 2 * _symbol3;
    bX = _zX3;
    bY = _zY3 - triangle.crossWidth / 2 * _symbol3;
    cX = _zX3;
    cY = _zY3 - triangle.bottomSide / 2 * _symbol3;
  }

  var context = me.rectangleCanvas.getContext("2d");
  context.beginPath();
  context.lineWidth = 1;
  context.moveTo(startX, startY);
  context.lineTo(rightX, rightY);
  context.lineTo(hX, hY);
  context.lineTo(endX, endY);
  context.lineTo(cX, cY);
  context.lineTo(bX, bY);
  context.lineTo(startX, startY);
  context.fillStyle = me.toolbarColor;
  context.fill();
  context.closePath();
}