var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        id: "offcieContainer",
        "element-loading-text": _vm.loadingTxt,
        "element-loading-background": "rgba(0, 0, 0, 0.5)"
      }
    },
    [
      _c("iframe", {
        attrs: { id: "iframeID", src: "", "allow-same-origin": "" },
        on: { load: _vm.iframeLoad, error: _vm.iframeError }
      }),
      _vm.clientBoolean ? _c("div", { attrs: { id: "mask" } }) : _vm._e(),
      _vm.createTag && !_vm.noLookComments
        ? _c(
            "el-button",
            {
              staticClass: "comments-btn",
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.openCreateComments }
            },
            [
              _c("i", { staticClass: "el-icon-circle-plus" }),
              _c("span", { staticClass: "text" }, [_vm._v("创建批注")])
            ]
          )
        : _vm._e(),
      _c("div", { attrs: { id: "createCommentsDialog" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.createCommentsVisible,
                expression: "createCommentsVisible"
              }
            ],
            staticClass: "createCommentsDialog",
            staticStyle: {
              position: "relative",
              width: "450px",
              margin: "40px 30px 40px auto",
              background: "#fff",
              "-webkit-box-shadow": "0 1px 3px rgba(0,0,0,.3)",
              "box-shadow": "0 1px 3px rgba(0,0,0,.3)",
              "border-radius": "2px"
            }
          },
          [
            _c("div", { staticClass: "header" }, [
              _c("span", { staticClass: "title" }, [_vm._v("创建批注")]),
              _c(
                "button",
                {
                  staticClass: "headerbtn",
                  attrs: { type: "button" },
                  on: { click: _vm.handleCreateCommentsClose }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ]),
            _c(
              "div",
              { staticStyle: { padding: "20px 30px 0px 30px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "queForm",
                    staticClass: "comments-form",
                    attrs: {
                      model: _vm.queForm,
                      rules: _vm.rules,
                      "label-width": "110px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题状态：", prop: "queStatusId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择问题状态",
                              disabled: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(1)
                              }
                            },
                            model: {
                              value: _vm.queForm.queStatusId,
                              callback: function($$v) {
                                _vm.$set(_vm.queForm, "queStatusId", $$v)
                              },
                              expression: "queForm.queStatusId"
                            }
                          },
                          _vm._l(_vm.DicOptions.statusList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.cnname, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题截图：", prop: "cutImageList" } },
                      [
                        _c("div", { staticClass: "MQcut" }, [
                          _c(
                            "div",
                            {
                              staticClass: "uploadpic",
                              on: { click: _vm.satrtScreenshot }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  id: "image1",
                                  src: require("./bg.png"),
                                  alt: "点击截图"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { title: "删除", id: "delbtn1" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteCutImage(
                                        "image1",
                                        "delbtn1"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "uploadpic",
                              on: { click: _vm.satrtScreenshot }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  id: "image2",
                                  src: require("./bg.png"),
                                  alt: "点击截图"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { title: "删除", id: "delbtn2" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteCutImage(
                                        "image2",
                                        "delbtn2"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "uploadpic",
                              on: { click: _vm.satrtScreenshot }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  id: "image3",
                                  src: require("./bg.png"),
                                  alt: "点击截图"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { title: "删除", id: "delbtn3" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteCutImage(
                                        "image3",
                                        "delbtn3"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题描述：", prop: "queDesc" } },
                      [
                        _c("div", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.queForm.queDesc,
                                expression: "queForm.queDesc"
                              }
                            ],
                            attrs: {
                              placeholder: "请输入问题描述",
                              id: "textareaBox"
                            },
                            domProps: { value: _vm.queForm.queDesc },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.queForm,
                                  "queDesc",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "专业：", prop: "specId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择专业" },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(2)
                              }
                            },
                            model: {
                              value: _vm.queForm.specId,
                              callback: function($$v) {
                                _vm.$set(_vm.queForm, "specId", $$v)
                              },
                              expression: "queForm.specId"
                            }
                          },
                          _vm._l(_vm.DicOptions.prjSpecList, function(item) {
                            return _c("el-option", {
                              key: item.specId,
                              attrs: {
                                label: item.specName,
                                value: item.specId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "跨专业：", prop: "multiSpecList" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: "请选择跨专业"
                            },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(3)
                              }
                            },
                            model: {
                              value: _vm.queForm.multiSpecList,
                              callback: function($$v) {
                                _vm.$set(_vm.queForm, "multiSpecList", $$v)
                              },
                              expression: "queForm.multiSpecList"
                            }
                          },
                          _vm._l(
                            _vm.DicOptions.prjSpecList.filter(function(a) {
                              return a.specId != _vm.queForm.specId
                            }),
                            function(item) {
                              return _c("el-option", {
                                key: item.specId,
                                attrs: {
                                  label: item.specName,
                                  value: item.specId
                                }
                              })
                            }
                          ),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题类型：", prop: "queTypeId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择问题类型" },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(4)
                              }
                            },
                            model: {
                              value: _vm.queForm.queTypeId,
                              callback: function($$v) {
                                _vm.$set(_vm.queForm, "queTypeId", $$v)
                              },
                              expression: "queForm.queTypeId"
                            }
                          },
                          _vm._l(_vm.DicOptions.typeList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.cnname, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "问题等级：", prop: "queFirstLevelId" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择问题等级" },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(5)
                              }
                            },
                            model: {
                              value: _vm.queForm.queFirstLevelId,
                              callback: function($$v) {
                                _vm.$set(_vm.queForm, "queFirstLevelId", $$v)
                              },
                              expression: "queForm.queFirstLevelId"
                            }
                          },
                          _vm._l(_vm.DicOptions.queLevelList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.cnname, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "修改方：", prop: "modifyPartyId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择修改方" },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(6)
                              }
                            },
                            model: {
                              value: _vm.queForm.modifyPartyId,
                              callback: function($$v) {
                                _vm.$set(_vm.queForm, "modifyPartyId", $$v)
                              },
                              expression: "queForm.modifyPartyId"
                            }
                          },
                          _vm._l(_vm.DicOptions.modifyPartyList, function(
                            item
                          ) {
                            return _c("el-option", {
                              key: item.depID,
                              attrs: { label: item.depName, value: item.depID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "要求完成时间：",
                          prop: "requireFinishTime"
                        }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            "picker-options": _vm.pickerOptions,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: _vm.queForm.requireFinishTime,
                            callback: function($$v) {
                              _vm.$set(_vm.queForm, "requireFinishTime", $$v)
                            },
                            expression: "queForm.requireFinishTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                staticStyle: {
                  padding: "0 50px 20px 50px",
                  "text-align": "right"
                },
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "maiaPlain", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.cancelQue("queForm")
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                !_vm.saveLoading
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "maiaPrimary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.saveQueSubmit("queForm")
                          }
                        }
                      },
                      [_vm._v("确 定")]
                    )
                  : _vm._e(),
                _vm.saveLoading
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "maiaPrimary",
                          size: "small",
                          disabled: ""
                        }
                      },
                      [_vm._v("正在创建...")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.queDetailVisible,
              expression: "queDetailVisible"
            }
          ],
          staticClass: "que-detail"
        },
        [
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "title" }, [_vm._v("批注详情")]),
            _c(
              "button",
              {
                staticClass: "headerbtn",
                attrs: { type: "button" },
                on: { click: _vm.closeQueDetail }
              },
              [_c("i", { staticClass: "el-icon-close" })]
            )
          ]),
          _c("ul", { staticClass: "detail-list" }, [
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("问题状态:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.queStatus))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("问题截图:")]),
              _c(
                "div",
                { staticClass: "con" },
                [
                  _c(
                    "viewer",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { images: _vm.queDetail.urlList }
                    },
                    _vm._l(_vm.queDetail.urlList, function(img, index) {
                      return _c("img", {
                        key: index,
                        staticClass: "short-img",
                        attrs: { src: img }
                      })
                    }),
                    0
                  )
                ],
                1
              )
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("问题描述:")]),
              _c("div", { staticClass: "con desc" }, [
                _vm._v(_vm._s(_vm.queDetail.queDesc))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("专业:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.specName))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("跨专业:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.multiSpec))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("问题类型:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.queTypeName))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("问题等级:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.queFirstLevelName))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("修改方:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.modifyPartyName))
              ])
            ]),
            _c("li", { staticClass: "item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("要求完成时间:")]),
              _c("div", { staticClass: "con" }, [
                _vm._v(_vm._s(_vm.queDetail.requireFinishTime))
              ])
            ])
          ]),
          _c("div", { staticClass: "reply-box" }, [
            _c("h3", [_vm._v("回复")]),
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "200",
                    "show-word-limit": "",
                    placeholder: "请填写回复内容"
                  },
                  model: {
                    value: _vm.replyText,
                    callback: function($$v) {
                      _vm.replyText = $$v
                    },
                    expression: "replyText"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btn-box" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.saveComment }
                  },
                  [_vm._v("发表回复")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowBigImage,
              expression: "isShowBigImage"
            }
          ],
          attrs: { id: "zoomimg" }
        },
        [
          _c("div", { staticClass: "zoomimgheader" }, [
            _c(
              "span",
              {
                attrs: { id: "closePreview" },
                on: { click: _vm.closeBigImage }
              },
              [_vm._v("X")]
            )
          ]),
          _vm._m(0)
        ]
      ),
      _c("div", { attrs: { id: "message" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgContainer" }, [
      _c("img", { attrs: { src: "", id: "bigImage" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }