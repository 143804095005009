import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
export function css(dom, obj) {
  for (var i in obj) {
    dom.style[i] = obj[i];
  }
}
export function remove(dom) {
  if (dom instanceof HTMLElement) {
    dom.parentNode.removeChild(dom);
  } else if (dom instanceof HTMLCollection) {
    Array.prototype.forEach.call(dom, function (obj) {
      obj.parentNode.removeChild(obj);
    });
  }
}
export function domType(dom) {
  return Object.prototype.toString.call(dom);
}
export function hasClass(obj, cls) {
  return obj.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
export function addClass(obj, cls) {
  if (!hasClass(obj, cls)) {
    obj.className += " " + cls;
  }
}
export function removeClass(obj, cls) {
  if (hasClass(obj, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    obj.className = obj.className.replace(reg, ' ');
  }
}
export function computed(obj, baseProperty, changeProperty, cb) {
  Object.defineProperty(obj, baseProperty, {
    set: function set(val) {
      changeProperty.forEach(function (it, index) {
        cb[index](obj, val, changeProperty[index]);
      });
    }
  });
}
export function typeChecking(val) {
  return Object.prototype.toString.call(val);
}